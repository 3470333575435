import React, { useContext } from "react"
import { Donate } from "../../assets/svgs"
import styled from "styled-components"
import { ThemeContext } from "../../contexts"
import { motion } from "framer-motion"
import { breakpoints } from "../../styles"

const DonateButton = () => {
  const { theme } = useContext(ThemeContext)
  return (
    <Button
      $theme={theme}
      transition={{ duration: 0.375 }}
      whileHover={{
        transform: "translateY(-4px)",
        filter: "drop-shadow(0px 4px 16px rgba(0,0,0,.5))",
      }}
      href="https://www.gofundme.com/f/paddy-barnes-v-stevo-timothy-boxing-spectacular/donate?utm_source=widget&utm_medium=referral&utm_campaign=p_cp+share-sheet"
      target="_blank"
    >
      <Donate />
      <span>DONATE HERE</span>
    </Button>
  )
}

const Button = styled(motion.a)`
  grid-column: 2/6;
  cursor: pointer;
  background: none;
  width: 15rem;
  height: 7.5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  text-decoration: none;
  margin-top: 2rem;
  filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.25));
  span {
    position: relative;
    z-index: 2;
    font-family: "Stencil";
    font-size: 1.125rem;
    font-weight: 600;
    color: ${({ $theme }) => $theme.darkColor};
  }
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  ${breakpoints.medium} {
    grid-column: 5/7;
  }
  ${breakpoints.large} {
    grid-column: 5/7;
  }
`
export default DonateButton
