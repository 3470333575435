import * as React from "react"

function SvgDonate(props) {
  return (
    <svg
      viewBox="0 0 234 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.165 28.326l3.845 53.25 227.754-15.43L227.109 0 2.165 28.326z"
        fill="#272727"
      />
      <path
        d="M8.337 78.765l219.517-17.292-2.497-58.626L3.234 29.453l5.103 49.312z"
        fill="#E62333"
      />
      <path
        d="M231.008 70.584l1.658-53.364L4.533 9.123l-.19 66.48 226.665-5.02z"
        fill="#272727"
      />
      <path
        d="M230.061 19.777L9.931 14.38 6.38 72.951l223.681-3.598V19.777z"
        fill="#FDD037"
      />
    </svg>
  )
}

export default SvgDonate
