import React, { createContext} from "react"
import { useLocalStorage } from "../hooks"

// Color Themes
const themes = {
  default: {
    title: 'default',
    primaryColor: "#FDD037",
    secondaryColor: "#E62333",
    darkColor: "#272727",
    lightColor: "white",
  },
  contrast: {
    title: 'contrast',
    primaryColor: "#FFEC00",
    secondaryColor: "#FFEC00",
    darkColor: "#000000",
    lightColor: "#FFEC00",
  },
  grayscale: {
    title: 'grayscale',
    primaryColor: "#FDD037",
    secondaryColor: "#E62333",
    darkColor: "#272727",
    lightColor: "white",
  },
}

// Font Sizes

const fontSizes = {
  default: {
    title: "default",
    size: "16px",
  },
  medium: {
    title: "medium",
    size: "18px",
  },
  large: {
    title: "large",
    size: "20px",
  },
}

// Create Context

const ThemeContext = createContext()

// Theme Provider

const ThemeProvider = ({ children }) => {
  
  const [theme, setTheme] = useLocalStorage("iwa-charity-theme", themes.default)
  const [fontSize, setFontSize] = useLocalStorage("iwa-charity-fontSize", fontSizes.default)

  const changeTheme = themeName => {
    themeName === 'contrast' ? setTheme(themes.contrast) : themeName === 'grayscale' ? setTheme(themes.grayscale) : setTheme(themes.default);
  }

  const changeFontSize = fontSize => {
    fontSize === 'large' ? setFontSize(fontSizes.large) : fontSize === 'medium' ? setFontSize(fontSizes.medium) : setFontSize(fontSizes.default);
  }

  // Return Provider

  return (
    <ThemeContext.Provider
      value={{ fontSize, changeFontSize, theme, changeTheme }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export { ThemeProvider, ThemeContext }
