import React, { useContext, useEffect, useState, useRef } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { AnimatePresence, motion } from "framer-motion"
import { breakpoints, GridContainer, Section } from "../../styles"
import Vimeo from "@vimeo/player"
import { useIntersection } from "../../hooks"
import { ThemeContext } from "../../contexts"
import { StaticImage } from "gatsby-plugin-image"
import { GoFundMeLogo } from "../../assets/svgs"

const Livestream = () => {
  const { theme } = useContext(ThemeContext)
  const [vimeoPlayer, setVimeoPlayer] = useState(null)
  const [livestreamIsPlaying, setLivestreamIsPlaying] = useState(false)
  const [pipRef, vimeoPlayerObserver] = useIntersection({
    initialInView: true,
    threshold: 0.75,
  })
  const [isPipModeEnabled, setIsPipModeEnabled] = useState(false)

  useEffect(() => {
    const livestreamIframe = document.getElementById("livestream-iframe")

    if (livestreamIframe) {
      const player = new Vimeo(livestreamIframe)

      setVimeoPlayer(player)

      player.on("play", () => {
        setLivestreamIsPlaying(true)
      })
      player.on("pause", () => {
        setLivestreamIsPlaying(false)
      })
      player.on("enterpictureinpicture", () => {
        setIsPipModeEnabled(true)
      })
      player.on("leavepictureinpicture", () => {
        setIsPipModeEnabled(false)
      })
    }
  }, [])

  useEffect(() => {
    if (
      document.pictureInPictureEnabled &&
      vimeoPlayer &&
      !vimeoPlayer.disablePictureInPicture &&
      livestreamIsPlaying &&
      !vimeoPlayerObserver.intersecting
    ) {
      try {
        vimeoPlayer.requestPictureInPicture()
      } catch (err) {
        console.error(err)
      }
    }
  }, [vimeoPlayer, vimeoPlayerObserver])

  return (
    <Container style={{ padding: "3rem 1rem", boxSizing: "border-box" }}>
      <LiveStreamVideo id="livestream" ref={pipRef} $theme={theme}>
        {/* <div
            ref={pipRef}
            style={{
              padding: "56.25% 0 0 0",
              height: "100%",
              position: "relative",
              overflow: "hidden",
            }}
          > */}
        {/* {eventStreamPlaceholderImg ? (
            <div
              style={{
                backgroundColor: "#fff",
                border: "1px solid #bdbdbd",
                height: "100%",
                left: 0,
                position: "absolute",
                top: 0,
                width: "100%",
              }}
            >
              <GatsbyImage
                image={getImage(eventStreamPlaceholderImg)}
                alt={`${eventName} Stream Placeholder`}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          ) : ( */}
        <iframe
          title="IWA - Stevo vs Timothy"
          id="livestream-iframe"
          src="https://player.vimeo.com/video/585255627"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
        {/* )} */}
        <AnimatePresence>
          {isPipModeEnabled && (
            <PipOverlay
              onClick={() => vimeoPlayer.exitPictureInPicture()}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              Click To Exit
              <br />
              Popout Player
            </PipOverlay>
          )}
        </AnimatePresence>
        {/* </div> */}
      </LiveStreamVideo>
      <GoFundMe $theme={theme}>
        <StaticImage
          src="../../assets/images/banner.jpg"
          alt="Stevo vs Timothy"
          placeholder="blurred"
          layout="fixed"
          loading="lazy"
          objectFit="cover"
        />
        <Details>
          <Title>Paddy Barnes v Stevo Timothy boxing spectacular</Title>
          <StatsBar>
            <AnimatedBar initial={{width:'0%'}} animate={{width: '100%'}} transition={{duration: 1, delay: 1}} />
            <Bar />
          </StatsBar>
          <AmountRaised>
            <h3>€40,986</h3> <p>raised of €40,000 goal</p>
          </AmountRaised>
          <Donate>
            <a href="https://www.gofundme.com/f/paddy-barnes-v-stevo-timothy-boxing-spectacular/donate?utm_source=widget&utm_medium=referral&utm_campaign=p_cp+share-sheet" target="blank">
              <button>Donate</button>
            </a>
            <GoFundMeLogo />
          </Donate>
        </Details>
      </GoFundMe>
    </Container>
  )
}

const Donate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    cursor: pointer;
    padding: 1rem 2rem;
    font-weight: 600;
    font-family: "Work Sans";
    width: 160px;
    background: linear-gradient(#fcb92d, #fa9a32);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.125);
    border: 1px solid #fa9a32;
    border-radius: 0.25rem;
    transition: 0.375s;
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    }
  }
  svg {
    width: 5rem;
    height: auto;
    path {
      fill: #04ae5c;
    }
  }
  ${breakpoints.large} {
    button {
      width: 160px;
    }
    svg {
      width: 7.5rem;
    }
  }
`

const AmountRaised = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-top: 0.25em;
  margin-bottom: 1em;
  h3 {
    font-size: 1.5rem;
    color: black;
    font-family: "Work Sans";
    margin: 0;
  }
  p {
    font-size: 0.75rem;
    font-family: "Work Sans";
    font-weight: 500;
    color: #999;
    letter-spacing: 0;
    margin: 0;
    margin-left: 0.5em;
    line-height: 1.75em;
  }

  ${breakpoints.extraLarge} {
    h3 {
      font-size: 2rem;
    }
    p {
      line-height: 2.25em;
    }
  }
`

const Bar = styled(motion.div)`
position: absolute;
top:0;
left:0;
height: 100%;
width: 100%;
background: #999;
`;

const AnimatedBar = styled(Bar)`
width:0;
background: #04ae5c;
z-index:2;
`

const StatsBar = styled.div`
  width: 100%;
  height: 5px;
  position: relative;
  margin: .75rem 0;
`;

const Title = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25em;
  margin: 0;
  color: black;
  font-family: "Work Sans";
  ${breakpoints.extraLarge} {
    font-size: 1.5rem;
  }
`

const Details = styled.div`
  background: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
`

const GoFundMe = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 350px;
  box-shadow: 0 0 0 3px ${({ $theme }) => $theme.primaryColor};
  .gatsby-image-wrapper {
    width: 100% !important;
    height: 100% !important;
    min-height: 200px;
    img {
      max-width: 100%;
      max-height: 100%;
      object-position: bottom center;
    }
  }

  ${breakpoints.large} {
    justify-self: start;
    align-self: start;
  }
`

const PipOverlay = styled(motion.div)`
  align-items: center;
  background-color: ${({ colors }) => colors.secondary};
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 2rem;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  width: 100%;
`

const LiveStreamVideo = styled.div`
  height: auto;
  position: relative;
  padding-bottom: 56.25%;
  margin-bottom: 3rem;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 3px ${({ $theme }) => $theme.primaryColor};
  }

  ${breakpoints.large} {
    margin-bottom: 0;
    margin: 0px;
    width: 100%;
    height: 100%;
  }

  ${breakpoints.large} {
    height: 100%;
    height: 100%;
    margin: 0px;
  }
`

const Container = styled(Section)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  padding: 1rem;
  ${breakpoints.large} {
    grid-template-columns: 2fr 1fr;
  }
`

export default Livestream
