import React, { useState, useContext } from "react"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import { H1, breakpoints } from "../../styles"
import { ThemeContext } from "../../contexts"
import { useLocation } from "@reach/router"
import { StaticImage } from "gatsby-plugin-image"
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsApp,
} from "../../assets/svgs"
import {
  LinkedinShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"
import { AnimatedEye } from "../../assets/animatedSvgs"
import { AccessibilityBar } from "../../components"

const Navbar = () => {
  const { theme } = useContext(ThemeContext)
  const [themeToggleOpen, setThemeToggleOpen] = useState(false)
  const { href } = useLocation()
  const toggleTheme = () => {
    setThemeToggleOpen(!themeToggleOpen)
  }

  return (
    <Nav
      $theme={theme}
      initial={{ transform: "translate(-50%, -100%)" }}
      animate={{ transform: "translate(-50%, 0%)" }}
      transition={{ duration: 0.375 }}
    >
      <Logo>
        <StaticImage
          src="../../assets/images/iwa-logo.png"
          alt="IWA Logo"
          placeholder="blurred"
          layout="fixed"
          objectFit="contain"
        />
      </Logo>
      <H1 $theme={theme}>
        <span>#</span>PADDY<span>V</span>STEVO
      </H1>
      <Socials $theme={theme}>
        <TwitterShareButton url={href}>
          <TwitterIcon />
        </TwitterShareButton>
        <FacebookShareButton url={href}>
          <FacebookIcon />
        </FacebookShareButton>
        <LinkedinShareButton url={href}>
          <LinkedinIcon />
        </LinkedinShareButton>
        <WhatsappShareButton url={href}>
          <WhatsApp />
        </WhatsappShareButton>
      </Socials>
      <ThemeToggle
        onClick={() => toggleTheme()}
        onMouseEnter={() => setThemeToggleOpen(true)}
        onMouseLeave={() => setThemeToggleOpen(false)}
        $open={themeToggleOpen}
        $theme={theme}
      >
        <AnimatedEye />
        <AnimatePresence>
          {themeToggleOpen && <AccessibilityBar />}
        </AnimatePresence>
      </ThemeToggle>
    </Nav>
  )
}

const ThemeToggle = styled.div`
  cursor: pointer;
  position: relative;
  width: 4rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: end;
  transition: 0.375s;
  overflow: visible;
  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(0 0, 100% 0, 81% 92%, 0% 92%);
    background: ${({ $open, $theme }) =>
      $open ? $theme.lightColor : $theme.primaryColor};
  }
  svg {
    position: relative;
    z-index: 2;
    width: auto;
    height: 1.25rem;
    max-height: 100%;
    path {
      stroke: ${({ $theme }) => $theme.darkColor};
      :last-child {
        fill: ${({ $theme }) => $theme.darkColor};
      }
    }
  }
  ${breakpoints.large} {
    width: 4rem;
    height: 4.25rem;
  }
`

const Socials = styled.div`
  height: auto;
  width: 14rem;
  justify-self: flex-end;
  display: none;
  justify-content: space-between;
  button {
    background-color: ${({ $theme }) => `${$theme.darkColor}!important`};
    width: 2rem;
    max-width: 2rem;
    height: 2rem;
    max-width: 2rem;
    margin-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.375s;
    &:hover {
      transform: scale(1.2);
    }
    svg {
      height: 1rem;
      width: 1rem;
    }
  }
  ${breakpoints.large} {
    display: flex;
  }
`

const Logo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .gatsby-image-wrapper {
    width: 8rem !important;
    height: 100% !important;
    img {
      max-width: 100%;
    }
  }
`

const Nav = styled(motion.nav)`
  grid-column: 1/7;
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 5;
  width: 1200px;
  max-width: 100%;
  max-height: 4.25rem;
  display: grid;
  grid-template-columns: 4.5rem 1fr 4.5rem;
  grid-column-gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
  transform: translate(-50%, -100%);
  &:before {
    content: "";
    background: rgba(255, 255, 255, 0.15);
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(1% 0, 98% 0, 97% 95%, 0 100%);
  }
  &:after {
    content: "";
    background: black;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0.25rem;
    width: calc(100% - 2.5rem);
    height: calc(100% - 0.25rem);
    clip-path: polygon(1% 0, 100% 0, 99% 95%, 0 100%);
  }
  ${breakpoints.medium} {
    padding: 0 1.5rem;
    grid-template-columns: 7.5rem 1fr 7.5rem;
  }
  ${breakpoints.large} {
    grid-template-columns: repeat(3, 1fr) 5rem;
    max-width: calc(90% - 5vw);
  }
`

export default Navbar
