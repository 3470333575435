import styled, { createGlobalStyle } from "styled-components"
import { motion } from "framer-motion"

export const breakpoints = {
  small: "@media (max-width:360px)",
  medium: "@media (min-width: 768px) and (max-width: 1200px)",
  large: "@media (min-width: 1200px)",
  extraLarge: "@media (min-width: 1400px)",
}

export const GlobalStyles = createGlobalStyle`
*{
  box-sizing: border-box;
}
html, body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'WorkSans';
  font-weight: 400;
  font-size: ${({ $fontSize }) => $fontSize.size};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body{
  background: ${({ $theme }) => $theme.darkColor};
  max-width:100%;
  filter: ${({ $theme }) => ($theme.title === 'grayscale' ? 'grayscale(100%)' : 'none')};
}
`

export const Section = styled.section`
  max-width: 100%;
  width: 1440px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 0.5rem;
  ${breakpoints.medium} {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 1rem;
  }
  ${breakpoints.large} {
    grid-template-columns: repeat(12, 1fr);
    margin: 0 auto;
  }
`

export const GridContainer = styled.div`
  grid-column: 1/7;
  padding: 1rem;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 0.5rem;
  ${breakpoints.medium} {
    grid-column: 2/12;
    grid-template-columns: repeat(10, 1fr);
  }
  ${breakpoints.large} {
    grid-column: 2/12;
    grid-template-columns: repeat(10, 1fr);
  }
`

export const FlexContainer = styled.div`
  grid-column: 1/7;
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${breakpoints.medium} {
    grid-column: 3/11;
  }
  ${breakpoints.large} {
    grid-column: 3/11;
  }
`
export const H1 = styled.h1`
  margin: 0;
  font-size: 1rem;
  font-family: "Stencil";
  text-align: center;
  color: ${({ $theme }) => $theme.primaryColor};
  line-height: 1em;
  span {
    color: white;
  }
  ${breakpoints.medium} {
    font-size: 2rem;
  }
  ${breakpoints.large} {
    font-size: 3rem;
  }
`

export const H2 = styled(motion.h2)`
  margin: 0;
  font-size: 3rem;
  font-family: "Stencil";
  text-align: center;
  color: ${({ $theme }) => $theme.primaryColor};
  line-height: 1em;
  opacity: 0;
  transform: rotateX(90deg);
  ${breakpoints.medium} {
    font-size: 3rem;
  }
  ${breakpoints.large} {
    font-size: 5rem;
  }
`

export const H3 = styled.h3`
  margin: 0;
  font-size: 1rem;
  font-family: "Stencil";
  text-align: center;
  color: ${({ $theme }) => $theme.lightColor};
  line-height: 1em;
  ${breakpoints.medium} {
    font-size: 2rem;
  }
  ${breakpoints.large} {
    font-size: 3rem;
  }
`

export const H4 = styled.h4`
  margin: 0;
  font-size: 1rem;
  font-family: "Stencil";
  text-align: center;
  color: ${({ $theme }) => $theme.primaryColor};
  line-height: 1em;
  ${breakpoints.medium} {
    font-size: 1.5rem;
  }
  ${breakpoints.large} {
    font-size: 1.5rem;
  }
`

export const P = styled.p`
  color: ${({ $theme }) => $theme.lightColor};
  font-size: 1rem;
  font-family: "Work Sans";
  text-align: center;
  line-height: 1.4em;
  small {
    font-size: 0.75em;
    font-weight: 500;
    line-height:1em;
  }
  ${breakpoints.medium} {
    font-size: 1.5rem;
  }
  ${breakpoints.large} {
    font-size: 1.5rem;
  }
`

export const Small = styled.small`
  color: ${({ $theme }) => $theme.lightColor};
  font-size: 1rem;
  font-family: "Work Sans";
  font-weight: 500;
  text-align: center;
  line-height: 1.4em;
  margin: 1.5rem auto;
  max-width: 500px;
  width: 100%;
  ${breakpoints.medium} {
    font-size: 1rem;
  }
  ${breakpoints.large} {
    font-size: 1rem;
  }
`
