import * as React from "react"

function SvgWhatsApp(props) {
  return (
    <svg
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.335 18.968l1.328-4.927A9.12 9.12 0 01.51 9.634C.51 4.607 4.643.522 9.726.522c5.084 0 9.216 4.085 9.216 9.112 0 5.026-4.132 9.111-9.216 9.111a9.074 9.074 0 01-4.382-1.114L.335 18.968zm5.26-3.17l.325.199a7.393 7.393 0 003.806 1.04c4.107 0 7.463-3.318 7.463-7.379 0-4.06-3.356-7.378-7.463-7.378S2.264 5.598 2.264 9.658c0 1.337.375 2.674 1.101 3.838l.2.322-.75 2.749 2.78-.768z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.157 10.921c-.376-.223-.877-.47-1.328-.297-.35.149-.55.668-.776.94-.125.15-.25.15-.426.1-1.277-.495-2.278-1.362-2.98-2.526-.125-.173-.1-.322.05-.495.226-.247.476-.545.552-.866.05-.347-.1-.719-.25-1.04-.176-.372-.377-.917-.777-1.14-.35-.197-.827-.098-1.152.174-.551.446-.801 1.139-.801 1.807 0 .199.025.397.075.57.1.446.325.867.55 1.263.176.297.376.594.577.866A9.027 9.027 0 009 12.605c.5.297 1.027.57 1.577.743.626.198 1.177.42 1.828.297.702-.124 1.403-.57 1.678-1.238.075-.198.125-.421.075-.62-.075-.42-.65-.668-1.001-.866z"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgWhatsApp
