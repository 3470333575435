import React, { useContext } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { breakpoints } from "../../styles"
import { ThemeContext } from "../../contexts"
import { StaticImage } from "gatsby-plugin-image"
import { AgencyXLogo, GbLogo } from "../../assets/svgs"

const Footer = () => {
  const { theme } = useContext(ThemeContext)
  return (
    <StyledFooter $theme={theme}>
      <LeftColumn $theme={theme}>
        <Logo>
          <StaticImage
            src="../../assets/images/iwa-logo-contrast.png"
            alt="IWA Logo"
            placeholder="blurred"
            layout="fixed"
            objectFit="contain"
          />
        </Logo>
        <p>Irish Wheelchair Association is a community of people with physical disabilities across Ireland founded on the belief that everyone should be able to live a life of choice and equality.</p>
      </LeftColumn>
      <RightColumn $theme={theme}>
        <a href="https://www.getbroadcasting.com/" target="blank">
          <GbLogo />
        </a>
        <a href="https://www.agencyx.ie/" target="blank">
          <AgencyXLogo />
        </a>

        <p>
          This Live Stream event is brought to you by Get Broadcasting in
          collaboration with Agency X.
        </p>
      </RightColumn>
    </StyledFooter>
  )
}

const Logo = styled.div`
  width: auto;
  height: auto;
  .gatsby-image-wrapper {
    width: 10rem !important;
    height: 5rem !important;
    margin-left:-1rem;
    img {
      max-width: 100%;
    }
  }
  ${breakpoints.medium}{
    .gatsby-image-wrapper{
      width: 12rem !important;
      height: 6rem !important;
    }
  }
`

const Column = styled.div`
  width: 350px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  p {
    color: ${({ $theme }) => $theme.lightColor};
  }
`

const LeftColumn = styled(Column)`
  align-items: flex-start;
  svg {
    width: 10rem;
    height: auto;
    margin-left: -1rem;
  }
  p {
    font-size: 1rem;
    line-height: 1.4rem;
    font-family: "Work Sans";
    margin-top: 0;
  }
`

const RightColumn = styled(Column)`
  align-items: flex-end;
  padding-right: 1rem;
  a {
    &:nth-child(1) {
      svg {
        width: 4rem;
        margin-bottom: 1rem;
      }
    }
    &:nth-child(2) {
      svg {
        width: 8rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  p {
    font-size: 0.75rem;
    line-height: 1rem;
    font-family: "Work Sans";
    text-align: right;
  }
`

const StyledFooter = styled(motion.footer)`
  position: relative;
  grid-column: 1/7;
  z-index: 3;
  width: calc(90% - 5vw);
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  padding-top: 4rem;
  &:before {
    content: "";
    background: black;
    position: absolute;
    z-index: -2;
    bottom: 0;
    left: -0.5rem;
    width: calc(100% + 0.75rem);
    height: 100%;
    clip-path: polygon(0 8%, 100% 0, 97% 100%, 0 100%);
  }
  &:after {
    content: "";
    background: #3c3d41;
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0rem;
    width: calc(100% - 1rem);
    height: calc(100% - 0.5rem);
    clip-path: polygon(0 8%, 100% 0, 99% 100%, 0 100%);
  }
  ${breakpoints.medium} {
    grid-column: 1/13;
    flex-direction: row;
  }
  ${breakpoints.large} {
    grid-column: 1/13;
    padding: 2rem 3rem;
    flex-direction: row;
  }
`

export default Footer
