import React from "react"
import { Layout } from "./src/components"
import { AnimatePresence } from "framer-motion"
import { ThemeProvider } from "./src/contexts"

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location

const wrapPageElement = ({ element, props }) => (
  <ThemeProvider>
    <AnimatePresence exitBeforeEnter>
      <Layout {...props}>{element}</Layout>
    </AnimatePresence>
  </ThemeProvider>
)

export default wrapPageElement
