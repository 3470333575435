import React from "react"
import styled from "styled-components"
import { breakpoints } from "../../styles"
import Logos from "./logos"

const LogoContainer = () => {
  return (
    <LogoGrid>
      {Logos.map(logo => (
        <span key={logo.id}>{logo.image}</span>
      ))}
    </LogoGrid>
  )
}

const LogoGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  grid-gap: 0.75rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  .gatsby-image-wrapper {
    height: 4rem !important;
  }
`

export default LogoContainer
