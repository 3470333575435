import React from "react"
import { StaticImage } from "gatsby-plugin-image"


const Logos = [
  {
    id: 1,
    title: `Ali's Barber`,
    image: (
      <StaticImage
        src="../../assets/images/sponsors/alis-barber.png"
        alt="Ali's Barber"
        placeholder="blurred"
        layout="fullWidth"
        objectFit="cover"
      />
    ),
  },
  {
    id: 2,
    title: `Apollo Health`,
    image: (
      <StaticImage
        src="../../assets/images/sponsors/apollo-health.png"
        alt="Apollo Health"
        placeholder="blurred"
        layout="fullWidth"
        objectFit="cover"
      />
    ),
  },
  {
    id: 3,
    title: `Cluck Chicken`,
    image: (
      <StaticImage
        src="../../assets/images/sponsors/cluck.png"
        alt="Cluck Chicken"
        placeholder="blurred"
        layout="fullWidth"
        objectFit="cover"
      />
    ),
  },
  {
    id: 4,
    title: `Connamara Kayaks`,
    image: (
      <StaticImage
        src="../../assets/images/sponsors/connamara-kayaks.png"
        alt="Cluck Chicken"
        placeholder="blurred"
        layout="fullWidth"
        objectFit="cover"
      />
    ),
  },
  {
    id: 5,
    title: `EO Precision`,
    image: (
      <StaticImage
        src="../../assets/images/sponsors/eo-precision.png"
        alt="EO Precision"
        placeholder="blurred"
        layout="fullWidth"
        objectFit="cover"
      />
    ),
  },
  {
    id: 6,
    title: `Galway Clayton Hotel`,
    image: (
      <StaticImage
        src="../../assets/images/sponsors/clayton-hotel.png"
        alt="Galway Clayton Hotel"
        placeholder="blurred"
        layout="fullWidth"
        objectFit="cover"
      />
    ),
  },
  {
    id: 7,
    title: `Get Broadcasting`,
    image: (
      <StaticImage
        src="../../assets/images/sponsors/get-broadcasting.png"
        alt="Get Broadcasting"
        placeholder="blurred"
        layout="fullWidth"
        objectFit="cover"
      />
    ),
  },
  {
    id: 8,
    title: `KyRy`,
    image: (
      <StaticImage
        src="../../assets/images/sponsors/kyry.png"
        alt="KyRy"
        placeholder="blurred"
        layout="fullWidth"
        objectFit="cover"
      />
    ),
  },
  {
    id: 9,
    title: `Monroe's`,
    image: (
      <StaticImage
        src="../../assets/images/sponsors/monroes.png"
        alt="Monroe's"
        placeholder="blurred"
        layout="fullWidth"
        objectFit="cover"
      />
    ),
  },
  {
    id: 10,
    title: `Nino's`,
    image: (
      <StaticImage
        src="../../assets/images/sponsors/ninos.png"
        alt="Nino's"
        placeholder="blurred"
        layout="fullWidth"
        objectFit="cover"
      />
    ),
  },
  {
    id: 11,
    title: `PPNI`,
    image: (
      <StaticImage
        src="../../assets/images/sponsors/ppni.png"
        alt="PPNI"
        placeholder="blurred"
        layout="fullWidth"
        objectFit="cover"
      />
    ),
  },
  {
    id: 12,
    title: `Provincial`,
    image: (
      <StaticImage
        src="../../assets/images/sponsors/provincial.png"
        alt="Provincial"
        placeholder="blurred"
        layout="fullWidth"
        objectFit="cover"
      />
    ),
  },
  {
    id: 13,
    title: `Sineirge`,
    image: (
      <StaticImage
        src="../../assets/images/sponsors/sineirge.png"
        alt="Sineirge"
        placeholder="blurred"
        layout="fullWidth"
        objectFit="cover"
      />
    ),
  },
  {
    id: 14,
    title: `Sound to Light`,
    image: (
      <StaticImage
        src="../../assets/images/sponsors/sound-to-light.png"
        alt="Sound to Light"
        placeholder="blurred"
        layout="fullWidth"
        objectFit="cover"
      />
    ),
  },
  {
    id: 15,
    title: `The Furniture Warehouse`,
    image: (
      <StaticImage
        src="../../assets/images/sponsors/furniture-warehouse.png"
        alt="Sound to Light"
        placeholder="blurred"
        layout="fullWidth"
        objectFit="cover"
      />
    ),
  },
  {
    id: 16,
    title: `The Warehouse Gym`,
    image: (
      <StaticImage
        src="../../assets/images/sponsors/warehouse-gym.png"
        alt="The Warehouse Gym"
        placeholder="blurred"
        layout="fullWidth"
        objectFit="cover"
      />
    ),
  },
]

export default Logos
