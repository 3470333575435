import React, { useContext } from "react"
import styled from "styled-components"
import { breakpoints } from "../../styles"
import { ThemeContext } from "../../contexts"
import { motion } from "framer-motion"

const AccessibilityBar = () => {
  const { theme, changeTheme, fontSize, changeFontSize } =
    useContext(ThemeContext)
  return (
    <ThemeDropdown
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
      $theme={theme}
    >
      <ThemeDropdownTitle>Accessibility</ThemeDropdownTitle>
      <ThemeColorOptions>
        <ThemeColorButton
          $theme={theme}
          $active={theme.title === "default"}
          onClick={() => changeTheme("default")}
        >
          Default
        </ThemeColorButton>
        <ThemeColorButton
          $theme={theme}
          $active={theme.title === "contrast"}
          onClick={() => changeTheme("contrast")}
        >
          Contrast
        </ThemeColorButton>
        <ThemeColorButton
          $theme={theme}
          $active={theme.title === "grayscale"}
          onClick={() => changeTheme("grayscale")}
        >
          Grayscale
        </ThemeColorButton>
      </ThemeColorOptions>
      <ThemeDropdownTitle $theme={theme}>Font Size</ThemeDropdownTitle>
      <ThemeFontOptions>
        <ThemeFontButton
          $theme={theme}
          $active={fontSize.title === "default"}
          onClick={() => changeFontSize("default")}
        >
          A
        </ThemeFontButton>
        <ThemeFontButton
          $theme={theme}
          $active={fontSize.title === "medium"}
          onClick={() => changeFontSize("medium")}
        >
          A
        </ThemeFontButton>
        <ThemeFontButton
          $theme={theme}
          $active={fontSize.title === "large"}
          onClick={() => changeFontSize("large")}
        >
          A
        </ThemeFontButton>
      </ThemeFontOptions>
    </ThemeDropdown>
  )
}

const ThemeDropdownTitle = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 3rem;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0.75rem;
  display: block;
  white-space: nowrap;
  ${breakpoints.large} {
    margin-bottom: 0px;
    margin-right: 1.25rem;
    margin-left: 1.25rem;
    :nth-child(1) {
      margin-left: 0px;
    }
  }
`

const ThemeColorOptions = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 1rem 2.5rem 1rem;
  ${breakpoints.large} {
    margin-bottom: 0px;
  }
`

const ThemeColorButton = styled.button`
  height: 2rem;
  font-weight: 600;
  background: ${({ $active, $theme }) =>
    ($active === true && $theme.title !== 'contrast') ? $theme.secondaryColor : ($active === true && $theme.title === 'contrast') ? $theme.darkColor : 'transparent'};
    border: ${({ $active, $theme }) =>
    ($active === true && $theme.title !== 'contrast') ? `1px solid ${$theme.secondaryColor}` : ($active === true && $theme.title === 'contrast') ? `1px solid ${$theme.darkColor}` : `1px solid ${$theme.darkColor}`};
  border-radius: 3rem;
  width: 6rem;
  max-width: 100%;
  font-size: 1rem;
  line-height: 1rem;
  color: ${({ $active, $theme }) =>
    $active === true ? $theme.lightColor : $theme.darkColor};
  cursor: pointer;
  :nth-child(2) {
    margin: 0px 0.625rem;
  }
`

const ThemeFontOptions = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 1rem 2.5rem 1rem;
  ${breakpoints.large} {
    margin-bottom: 0px;
  }
`

const ThemeFontButton = styled.button`
  height: 2rem;
  background: ${({ $active, $theme }) =>
    ($active === true && $theme.title !== 'contrast') ? $theme.secondaryColor : ($active === true && $theme.title === 'contrast') ? $theme.darkColor : 'transparent'};
  border: ${({ $active, $theme }) =>
  ($active === true && $theme.title !== 'contrast') ? `1px solid ${$theme.secondaryColor}` : ($active === true && $theme.title === 'contrast') ? `1px solid ${$theme.darkColor}` : `1px solid ${$theme.darkColor}`};
  border-radius: 3rem;
  width: 3rem;
  font-weight: 600;
  color: ${({ $active, $theme }) =>
    $active === true ? $theme.lightColor : $theme.darkColor};
  cursor: pointer;
  :nth-child(1) {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  :nth-child(2) {
    margin: 0px 1.25rem;
    font-size: 1rem;
    line-height: 1.25rem;
  }
  :nth-child(3) {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
`

const ThemeDropdown = styled(motion.div)`
  position: fixed;
  top: 3.75rem;
  z-index: 1;
  left: 0;
  padding: 0.625rem 1.25rem;
  background: ${({ $theme }) => $theme.lightColor};
  width: 100%;
  box-shadow: 0px 0.185rem 0.625rem rgba(0, 0, 0, 0.25);
  font-family: "Work Sans";
  transform-origin: top center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: scaleY(100%) !important;
  h4 {
    color: ${({ $theme }) => $theme.darkColor};
    margin: 0;
  }
  ${breakpoints.large} {
    flex-direction: row;
    position: absolute;
    z-index: 2;
    top: 3.9rem;
    right: 0px;
    left: initial;
    width: auto;
    display: flex;
    align-items: center;
  }
`

export default AccessibilityBar
