import * as React from "react"

function SvgLinkedinIcon(props) {
  return (
    <svg
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.339 16.19V5.482H.126V16.19h3.213zm0-14.533C3.339.701 2.709 0 1.638 0 .693 0 0 .701 0 1.657c0 .956.63 1.658 1.638 1.658 1.07.063 1.7-.702 1.7-1.658zM8.693 16.19v-6.119c0-.319 0-.637.126-.892.252-.638.819-1.339 1.827-1.339 1.322 0 2.078.765 2.078 2.231v6.12H16V9.943c0-3.379-1.764-4.908-4.095-4.908-1.952 0-2.771 1.083-3.275 1.848V5.482H5.417c.063 1.02 0 10.772 0 10.772h3.276v-.064z"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgLinkedinIcon
