import React, { useContext } from "react"
import "../../fonts/fonts.css"
import { Navbar } from "../../components"
import { GlobalStyles } from "../../styles"
import { ThemeContext } from "../../contexts"
import styled from 'styled-components'
import { motion } from 'framer-motion'

const Layout = ({ children }) => {
  const { fontSize, theme } = useContext(ThemeContext)
  
  return (
    <Wrapper initial={{opacity:0}} animate={{opacity:1}} transition={{duration: 1}}>
      <GlobalStyles $fontSize={fontSize} $theme={theme} />
      <Navbar />
      {children}
    </Wrapper>
  )
}

const Wrapper = styled(motion.div)`
width: 100%;
height: auto;
`

export default Layout
