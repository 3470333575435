import * as React from "react"

function SvgAgencyXLogo(props) {
  return (
    <svg
      viewBox="0 0 104 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.23 0c1.891 0 3.265.77 4.311 1.977l-.74.534C22.999 1.54 21.689.898 20.23.898c-2.695 0-4.777 2.062-4.777 5.086 0 2.992 2.082 5.108 4.777 5.108 1.533 0 2.738-.77 3.372-1.41V6.934h-4.746l.391-.908h5.327v4.05A5.805 5.805 0 0120.23 12c-3.234 0-5.813-2.436-5.813-6.016C14.416 2.415 16.996 0 20.23 0zM44.538 1.774v10.002h-.983V.192h.994l7.187 9.863V.192h.983v11.584h-.962L44.538 1.774zM58.33 5.995C58.33 2.436 60.89 0 64.103 0c1.86 0 3.234.812 4.206 2.052l-.824.534C66.765 1.58 65.507.898 64.102.898c-2.664 0-4.746 2.062-4.746 5.086 0 2.992 2.082 5.086 4.745 5.086 1.406 0 2.664-.673 3.383-1.688l.824.524c-1.015 1.271-2.357 2.062-4.207 2.062-3.212.021-5.77-2.415-5.77-5.973zM76.647 6.828L72.092.192h1.183l3.869 5.696L81.012.192h1.184L77.64 6.828v4.948h-.994V6.828zM5.908.192H4.693L0 11.776h1.1L5.294 1.165l2.748 6.86H4.64l-.37.908h4.08l1.152 2.843H10.6L5.908.192zM37.87.192h-7.653v.909h7.652V.192zM35.903 5.193h-5.686v.898h5.295l.391-.898zM37.87 10.878h-7.653v.908h7.652v-.908z"
        fill="#fff"
      />
      <path
        d="M97.648 6.518l-4.111 5.279h1.236l3.477-4.563-.602-.716z"
        fill="#CE9200"
      />
      <path
        d="M99.392 5.824l4.354-5.632h-1.236l-3.731 4.916-3.71-4.916h-1.258l8.973 11.584H104l-4.608-5.952z"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgAgencyXLogo
