import React, { useContext } from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { H1, Small, breakpoints } from "../../styles"
import { ThemeContext } from "../../contexts"
import { Link } from "gatsby"
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  InstaIcon,
  WhatsApp,
} from "../../assets/svgs"
import {
  LinkedinShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"
import { useLocation } from "@reach/router"

const SocialPromo = () => {
  const { theme } = useContext(ThemeContext)
  const { href } = useLocation()
  return (
    <Container>
      <Artwork>
        <StaticImage
          src="../../assets/images/boom.png"
          alt="Boom Social"
          placeholder="blurred"
          layout="fixed"
          loading="lazy"
          objectFit="contain"
          className="boom-social"
        />
        <Artist $theme={theme}>
          <p>Artwork by Niall O'Loughlin</p>
          <p>
            <InstaIcon /> <strong>niall.oloughlin.artist</strong>
          </p>
        </Artist>
      </Artwork>
      <Small $theme={theme}>share this event</Small>
      <H1 $theme={theme}>
        <span>#</span>PADDY<span>V</span>STEVO
      </H1>
      <Socials $theme={theme}>
      <TwitterShareButton url={href}>
          <TwitterIcon />
        </TwitterShareButton>
        <FacebookShareButton url={href}>
          <FacebookIcon />
        </FacebookShareButton>
        <LinkedinShareButton url={href}>
          <LinkedinIcon />
        </LinkedinShareButton>
        <WhatsappShareButton url={href}>
          <WhatsApp />
        </WhatsappShareButton>
      </Socials>
    </Container>
  )
}

const Artist = styled.div`
  position: absolute;
  right: 50%;
  top: -5rem;
  width: auto;
  transform: translateX(50%);
  svg {
    height: 1rem;
    width: 1rem;
    margin-bottom: -0.25rem;
    margin-right:.5rem;
    path {
      stroke: ${({ $theme }) => $theme.primaryColor};
    }
  }
  p {
    color: ${({ $theme }) => $theme.lightColor};
    margin: 0;
    margin-bottom: 0.5em;
    line-height: 1em;
    font-family: "Work Sans";
    display:flex;
  }
  ${breakpoints.large} {
    right: -10rem;
    top: 16rem;
    transform: none;
  }
  ${breakpoints.large} {
    right: -16rem;
    top: 12rem;
    transform: none;
  }
`

const Artwork = styled.div`
  position: relative;
  .gatsby-image-wrapper{
    width: 300px !important;
    height: 450px !important;
    max-width:100% !important;
    ${breakpoints.medium}{
      width:400px !important;
    }
    ${breakpoints.large}{
      width:400px !important;
    }
  }
`

const Socials = styled.div`
  height: auto;
  width: 14rem;
  justify-self: flex-end;
  display: flex;
  justify-content: space-between;
  margin-top:3rem;
  button {
    background-color: #000 !important;
    width: 2rem;
    max-width: 2rem;
    height: 2rem;
    max-width: 2rem;
    margin-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.375s;
    &:hover {
      transform: scale(1.2);
    }
    svg {
      height: 1rem;
      width: 1rem;
    }
  }
`

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 3rem auto;
  .gatsby-image-wrapper {
    width: 100%;
    max-width: 400px;
    max-height: 300px;
    height: auto;
  }
`

export default SocialPromo
