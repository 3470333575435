import React, { useContext } from "react"
import styled from "styled-components"
import { ThemeContext } from "../../contexts"
import { StaticImage } from "gatsby-plugin-image"
import { Section, GridContainer, H2, H3, breakpoints } from "../../styles"
import { DonateButton } from "../../components"
import { motion } from "framer-motion"
import { Badge, SpeechBubble } from "../../assets/svgs"

const Header = () => {
  const { theme } = useContext(ThemeContext)
  return (
    <StyledHeader $theme={theme}>
      <Background>
        <StaticImage
          src="../../assets/images/background.jpg"
          alt="IWA Background"
          placeholder="blurred"
          layout="fixed"
          objectFit="contain"
          className="background-image"
        />
      </Background>
      <Container>
        <DateBadge $theme={theme}>
          <Badge />
        </DateBadge>
        <Fighter $theme={theme}>
          <p>3-Time Olympic Bronze Medal Winning Boxer</p>
          <H2
            $theme={theme}
            initial={{ opacity: 0, transform: "rotateX(90deg)" }}
            animate={{ opacity: 1, transform: "rotateX(0deg)" }}
            transition={{ delay: 0.25, duration: 0.5 }}
          >
            PADDY
            <br />
            BARNES
          </H2>
        </Fighter>
        <VS
          initial={{ opacity: 0, transform: "scale(0)" }}
          animate={{ opacity: 1, transform: "scale(1)" }}
          transition={{ delay: 0.5, duration: 0.5 }}
        >
          <StaticImage
            src="../../assets/images/vs.png"
            alt="Versus"
            placeholder="blurred"
            layout="fixed"
            loading="lazy"
            objectFit="contain"
            className="vs-image"
          />
        </VS>
        <Fighter $theme={theme}>
          <p>Comedian. Genius. Farmer Michael.</p>
          <H2
            $theme={theme}
            initial={{ opacity: 0, transform: "rotateX(90deg)" }}
            animate={{ opacity: 1, transform: "rotateX(0deg)" }}
            transition={{ delay: 1, duration: 0.5 }}
          >
            STEVO
            <br />
            TIMOTHY
          </H2>
        </Fighter>
        <Location $theme={theme}>Live from the Clayton Hotel, Galway</Location>
        <Footnote $theme={theme}>
          brought to you by Get Broadcasting and Agency X
        </Footnote>
        <DonateButton />
        <Lineup $theme={theme}>Artem Lobov V Kieran Burns <span>|</span> John Connors V Lyonsey “MC Daycent” <span>|</span> Dylan Moran V Fabu-D <span>|</span> Darren Conway V Al Foran</Lineup>
        <Bubble $theme={theme}>
          <SpeechBubble />
        </Bubble>
      </Container>
    </StyledHeader>
  )
}

const Lineup = styled.p`
  grid-column: 1/7;
  text-align:center;
  font-size: 1rem;
  font-family: 'Work Sans';
  font-weight: 600;
  line-height:1.4em;
  color white;
  span{
    color: ${({$theme}) =>  $theme.primaryColor};
    margin: 0 .25em;
  }
  ${breakpoints.medium}{
    grid-column: 2/10;
  }
  ${breakpoints.large}{
    grid-column: 3/9;
  }
`

const DateBadge = styled.div`
  display: none;
  position: absolute;
  left: -5rem;
  top: 0rem;
  width: 20rem;
  height: auto;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    path {
      &:nth-child(2) {
        fill: ${({ $theme }) => $theme.secondaryColor};
      }
      &:nth-child(3) {
        fill: ${({ $theme }) =>
          $theme.title === "contrast" ? $theme.darkColor : $theme.lightColor};
      }
    }
  }
  ${breakpoints.medium} {
    display: block;
  }
  ${breakpoints.large} {
    display: block;
  }
`

const Bubble = styled.div`
  display: none;
  position: absolute;
  right: -5rem;
  top: -3rem;
  width: 10rem;
  height: auto;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    path {
      &:nth-child(2) {
        fill: ${({ $theme }) =>
          $theme.title === "contrast" ? $theme.primaryColor : "#0095DA"};
      }
      &:last-child {
        fill: ${({ $theme }) =>
          $theme.title === "contrast" ? $theme.darkColor : $theme.lightColor};
      }
    }
  }
  ${breakpoints.medium} {
    display: block;
  }
  ${breakpoints.large} {
    display: block;
  }
`

const Footnote = styled.p`
  grid-column: 1/7;
  text-align:center;
  font-size: 1rem;
  font-family: 'Work Sans';
  font-weight: 600;
  line-height:1em;
  color ${({ $theme }) => $theme.primaryColor};
  ${breakpoints.medium}{
    grid-column: 1/11;
  }
  ${breakpoints.large}{
    grid-column: 1/11;
  }
`

const Location = styled(H3)`
  grid-column: 1/7;
  text-align:center;
  font-family: 'Stencil';
  line-height:1em;
  margin-top:3rem;
  margin-bottom:0;
  color ${({ $theme }) => $theme.lightColor};
  ${breakpoints.medium}{
    grid-column: 1/11;
  }
  ${breakpoints.large}{
    grid-column: 1/11;
  }
`

const VS = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1/7;
  transform: scale(0);
  opacity: 0;
  .vs-image {
    width: 200px !important;
    height: 120px !important;
    img{
      max-width:100%;
    }
  }
  ${breakpoints.medium} {
    grid-column: span 2;
    margin-top: 5rem;
    .vs-image {
      width: 100% !important;
      height: 150px !important;
    }
  }
  ${breakpoints.large} {
    grid-column: span 2;
    margin-top: 5rem;
    .vs-image {
      width: 100% !important;
      height: 200px !important;

    }
  }
`

const Fighter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-column: 1/7;
  p {
    font-family: "Work Sans";
    font-weight: 600;
    font-size: 1.25rem;
    text-align: center;
    color: ${({ $theme }) => $theme.lightColor};
  }
  ${breakpoints.medium} {
    grid-column: span 4;
  }
  ${breakpoints.large} {
    grid-column: span 4;
  }
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .background-image {
    width: 100% !important;
    height: 100% !important;
    max-height: 100%;
    object-fit: cover;
    opacity: 0.4;
    img {
      object-position: center;
      object-fit: cover !important;
    }
  }
`

const Container = styled(GridContainer)`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  ${breakpoints.medium} {
    padding-top: 3rem;
  }
  ${breakpoints.large} {
    padding-top: 3rem;
  }
`

const StyledHeader = styled(Section)`
  position: relative;
  width: 100%;
  height: auto;
  color: ${({ $theme }) => $theme.secondaryColor};
  padding: 3rem 0;
  ${breakpoints.medium}{
    padding: 6rem 0;
  }
  ${breakpoints.large}{
    padding: 6rem 0;
  }
`

export default Header
